import {fullReady, HadronCollider} from "@webreact/webreactor";
import components from './components';

__webpack_public_path__ = document.head.querySelector('meta[name="base_assets"]').content;


const collider = new HadronCollider(components);

/**
 * Fire this function when the document is fully loaded.
 * It takes no parameters and should return nothing.
 *
 * @return void
 */
function loaded() {
    collider.loadEvents();
}

fullReady(loaded);
