export default [
    {
        target: '[data-component="recent-objects"]',
        file: async () =>
            (await import(/* webpackChunkName: "scripts/components/recent-objects" */'./elements/recent-objects')).default,
    },
    {
        target: '[data-component="testimonials"]',
        file: async () =>
            (await import(/* webpackChunkName: "scripts/components/testimonials" */'./elements/testimonials')).default,
    },
    {
        target: '[data-component="employees"]',
        file: async () =>
            (await import(/* webpackChunkName: "scripts/components/employees" */'./elements/employees')).default,
    },
    {
        target: '[data-component="usps"]',
        file: async () =>
            (await import(/* webpackChunkName: "scripts/components/usps" */'./elements/usps')).default,
    },
    {
        target: '[data-component="subhero-usps"]',
        file: async () =>
            (await import(/* webpackChunkName: "scripts/components/hero" */'./elements/hero')).default,
    }
]